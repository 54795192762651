<template>
  <div class="container-fluid common-error-404-outer">
    <div
      class="
        d-flex
        justify-content-center
        align-items-center
        common-error-404-inner
      "
    >
      <div class="common-error-404-box">
        <div class="error-404-baner">
          <div class="text-center">
            <img
              src="https://storage.googleapis.com/auto-recruit-prod-project-public-files/autorecruits-web-v2-assets/images/baner/404.svg"
              class="rounded mx-auto d-block"
              alt="..."
            />
          </div>
        </div>
        <div class="heading">Page Not Found</div>
        <div class="subheading">
          <div>We're sorry, the page you requested could not be found</div>
          <div>Please go back to the homepage</div>
        </div>
        <div class="back-to-home-btn">
          <button type="submit" variant="dark" class="w-100 custom-btn-45">
            Home Page
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.common-error-404-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 0;
}
.common-error-404-outer .common-error-404-inner {
  height: 100vh;
}
.common-error-404-outer .common-error-404-inner .common-error-404-box {
  width: 400px;
}
.common-error-404-box .error-404-baner {
  padding-bottom: 47px;
  text-align: center;
  padding-top: 47px;
}
.common-error-404-box .heading {
  font-family: 'AcuminPro-SemiBold';
  font-size: 24px;
  letter-spacing: 0px;
  color: #2a2a2a;
  line-height: 33px;
  text-align: center;
  padding-bottom: 10px;
}
.common-error-404-box .subheading {
  font-family: 'AcuminPro-Regular';
  font-size: 14px;
  letter-spacing: 0px;
  color: #2a2a2a;
  text-align: center;
  line-height: 25px;
}
.common-error-404-box .back-to-home-btn {
  width: 322px;
  margin: 0 auto;
}
</style>
